<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 广告区设置 </template>
      <template #input>
        <a-button class="all_boder_btn" @click="goDetail('',type)">新建图片</a-button>
        <a-button class="all_boder_btn" @click="edit = true">新建位置</a-button>
      </template>
    </HeaderBox>
    <div class="content">
      <div class="table-box">
        <a-tabs
          :default-active-key="type"
          @change="callback"
          tabPosition="left"
        >
          <a-tab-pane
            v-for="item in bannerLocationList"
            :key="item.id"
          >
            <template #tab>
              <div :title="item.remark" style="text-align: left;">
                {{ item.locationTitle }}
                <a-icon @click.stop="editForm(item)" type="edit" />
              </div>
            </template>
          </a-tab-pane>
        </a-tabs>
      </div>
      <a-table
        class="table-template"
        @change="onPage"
        :rowKey="(item) => item.id"
        :loading="loading"
        :columns="columns"
        :data-source="tableData"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          {{ (pageNumber - 1) * pageSize + i + 1 }}
        </template>

        <template slot="bannerLink" slot-scope="item">
          <img class="bannerLink" :src="item" alt="" @click="openImg(item)">
        </template>

        <template slot="jumpType" slot-scope="item">
          <span>{{jumpFlagFont(item)}}</span>
        </template>
        
        <template slot="isEnable" slot-scope="item">
          {{ item == 1?'已启用':'已停用' }}
        </template>

        <template slot="sort" slot-scope="item,row">
          <a-input-number id="inputNumber" v-model="item" :min="1" @change="onSortChange(item,row)" />
        </template>
        
        <template slot="operation" slot-scope="item, row, index">
          <span class="blueText">
            
            <span @click="goDetail(row.id,row.locationId)">编辑</span> |
            <span @click="editFilling(row)">{{row.isEnable == 1?'停用':'启用'}}</span> |
            <span @click="deleteData(row)">删除</span>
          </span>
        </template>
      </a-table>
    </div>
    
    <a-modal
      v-model="edit"
      width="530px"
      :title="formObj.id?'编辑位置信息':'新建位置'"
      :centered="true"
      :closable="false"
    >
      <div class="all_content_box">
        <div class="L_R_inner">
          <span class="all_left_name">位置名称：</span>
          <div class="right_Div">
            <a-input
              class="all_input"
              placeholder="请输入位置名称（建议在十个字之内）"
              v-model="formObj.locationTitle"
            />
          </div>
        </div>
        <div class="L_R_inner">
          <span class="all_left_name">位置编码：</span>
          <div class="right_Div">
            <a-input
              class="all_input"
              placeholder="请输入位置编码"
              v-model="formObj.locationCode"
            />
          </div>
        </div>
        <div class="L_R_inner">
          <span class="all_left_name">关联系统：</span>
          <div class="right_Div">
            <a-select
              mode="multiple"
              class="all_input"
              placeholder="请选择关联系统"
              v-model="formObj.localSystem"
            >
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option
                value="app"
                >app</a-select-option
              >
              <a-select-option
                value="pc"
                >pc</a-select-option
              >
              <a-select-option
                value="org"
                >机构端</a-select-option
              >
            </a-select>
          </div>
        </div>
        <div class="L_R_inner">
          <span class="all_left_name">尺寸建议：</span>
          <div class="right_Div">
            <a-input
              class="all_input"
              placeholder="请输入尺寸建议  例: 300 * 500"
              v-model="formObj.picSize"
            />
          </div>
        </div>
        <div class="L_R_inner">
          <span class="all_left_name">描述说明：</span>
          <div class="right_Div">
            <a-textarea
              v-model="formObj.remark"
              class="all_input"
              placeholder="请输入描述说明"
              :maxLength="255"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              style="resize: none"
            />
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="PreventLoad" @click="addEditData()">确认</a-button>
        <a-button @click="editCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "标题/主题",
    align: "center",
    dataIndex: "bannerTitle"
  },
  {
    title: "封面",
    align: "center",
    dataIndex: "bannerLink",
    scopedSlots: {
      customRender: "bannerLink",
    },
  },
  {
    title: "跳转类型",
    align: "center",
    dataIndex: "jumpType",
    scopedSlots: {
      customRender: "jumpType",
    },
  },
  {
    title: "排序",
    align: "center",
    dataIndex: "sort",
    scopedSlots: {
      customRender: "sort",
    },
  },
  // {
  //   title: "创建时间",
  //   align: "center",
  //   dataIndex: "createTime",
  // },
  {
    title: "状态",
    align: "center",
    dataIndex: "isEnable",
    scopedSlots: {
      customRender: "isEnable",
    },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      // 弹框数据
      formObj: {},
      bannerLocationList: [], // 位置数据
      type: undefined,  //类型 1.首页资讯 2.教资资讯
      columns,
      loading: false,
      state: 1, //1添加 2编辑
      newTypeObj: {}, //分类名称obj
      typeName: "", //分类名称
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      edit: false,
      PreventLoad: false
    };
  }, // 事件处理器
  methods: {
    // 打开图片
    openImg(item) {
      window.open(item)
    },
    // 跳转详情页
    goDetail(e,type) {
      let code = '';
      this.bannerLocationList.some(item=>{
        if(type == item.id) {
          code = item.locationCode;
          return true;
        }
      })
      this.$router.push('/admin/ContentConfig/AdvertiseDetail?edit=' + e + '&type=' + type + '&code=' + code);
    },
    jumpFlagFont(type){
      const fontMap = { 1 : '外部网页链接', 2 : '资讯文章详情', 3 : '课程详情', 4 : '直播详情', 6 : '优惠券弹框资讯列表', 7 : '商品栏目列表'}
      return type ? fontMap[type] : '-'
    },
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },
    callback(e) {
      this.type = e;
      this.pageNumber = 1;
      this.getData()
    },
    // 获取位置列表
    getBannerLocationList() {
      this.$ajax({
        url: "/hxclass-management/BannerLocation/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.bannerLocationList = res.data;
          if(res.data && res.data.length && !this.type) {
            this.type = res.data[0].id
            this.getData() // 获取数据
          }
        }
      });
    },
    // 数据列表
    getData() {
      if(this.loading) {
        return console.log('请稍后...')
      }
      this.loading = true;
      let params = {
        locationId: this.type,
        pageNum: this.pageNumber,
        pageSize: this.pageSize
      }
      this.$ajax({
        url: "/hxclass-management/Banner/list",
        params
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 新增/修改
    addEditData() {
      if (!this.formObj.locationTitle) {
        return this.$message.warning("请输入位置名称！");
      }else if (!this.formObj.locationCode) {
        return this.$message.warning("请输入位置编码！");
      }else if (!this.formObj.localSystem) {
        return this.$message.warning("请选择关联系统！");
      }else if (!this.formObj.picSize) {
        return this.$message.warning("请输入建议尺寸！");
      }else if (!this.formObj.remark) {
        return this.$message.warning("请输入备注描述！");
      }
      let params = JSON.parse(JSON.stringify(this.formObj));
      params.localSystem = params.localSystem.join(',');
      this.PreventLoad = true;
      this.$ajax({
        url: '/hxclass-management/BannerLocation/update',
        method: 'post',
        params: params
      }).then((res) => {
        this.PreventLoad = false;
        if (res.code == 200 && res.success) {
          this.getBannerLocationList();
          this.edit = false;
          this.$message.success(this.formObj.id ? "修改成功" : "新增成功");
          this.formObj = {};
        } else {
          this.edit = false;
          this.formObj = {};
          this.$message.warning(res.message);
        }
      });
    },
    // 删除
    deleteData(item) {
      let _this = this;
      this.$confirm({
        title: "确定删除该条数据吗?",
        okText: "确认",
        onOk() {
          _this.visible = false;
          _this
            .$ajax({
              url: "/hxclass-management/Banner/delete/" + item.id,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.visible = false;
                _this.getData();
              }
            });
        },
        onCancel() {},
      });
    },
    // 停用
    editFilling(item) {
      let _this = this;
      this.$confirm({
        title: "确定" + (item.isEnable == 1 ? '停用' : '启用') + "该条数据吗?",
        okText: "确认",
        onOk() {
          item.isEnable = item.isEnable == 1?0:1;
          _this
            .$ajax({
              url: "/hxclass-management/Banner/update",
              method: "post",
              params: item
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("操作成功");
                _this.visible = false;
                _this.getData();
              }
            });
        },
        onCancel() {},
      });
    },
    // 修改位置
    onSortChange(e,item) {
      // console.log(e)
      item.sort = e;
      if(this.timer) {
        clearTimeout(this.timer)
      }
      let _this = this;
      this.timer = setTimeout(()=>{
        this.timer = null;
        _this
          .$ajax({
            url: "/hxclass-management/Banner/update",
            method: "post",
            params: item
          })
          .then((res) => {
            if (res.code == 200 && res.success) {
              _this.$message.success("操作成功");
              _this.getData();
            }
          });
        // console.log('zoule')
      },600)
    },
    // 修改位置信息
    editForm(e) {
      this.formObj = JSON.parse(JSON.stringify(e));
      this.formObj.localSystem = this.formObj.localSystem.split(',')
      this.edit = true;
    },
    // 弹框取消
    editCancel() {
      this.formObj = {};
      this.edit = false;
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    // 获取位置列表
    this.getBannerLocationList();
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.content{
  display: flex;
  .table-template{
    flex: 1;
  }
}
.all_main_wrap {
  min-height: 100%;

  .table-box {
    display: flex;
    justify-content: flex-start;
    padding-top: 20px;
    .btn{
      color: #3681F0;
      cursor: pointer;
    }
    /deep/ .ant-tabs-bar {
      border-bottom: none;
    }

    /deep/.ant-tabs-bar {
      margin: 0;
    }

    .all_boder_btn {
      margin-top: 10px;
    }
  }
}
.all_content_box {
  .all_input {
    width: 340px;
  }
  /deep/ .ant-select{
    padding: 0;
    .ant-select-selection--single,
    .ant-select-selection__rendered,
    .ant-select-selection-selected-value{
      height: 40px;
      line-height: 38px;
    }
  }
}
/deep/.ant-modal-header {
  padding: 16px 24px 0px;
  border-bottom: none;
}
/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 24px 24px 14px;
}
/deep/ .ant-btn {
  margin-right: 24px;
}
.blueText span {
  cursor: pointer;
}
.bannerLink{
  max-width: 200px;
  max-height: 100px;
  cursor: pointer;
}
</style>
